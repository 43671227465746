module.exports = global.config = {
	geturl: {
		ipaddress: {
			//  url: "https://apitest.jobanya.com/api/controller/"// TEST
			// url: "https://uatapi.jobanya.com/api/controller/" //UAT
			// url: "https://apilive.jobanya.com/api/controller/" //Live
			url: 'https://apilive.jobanya.com/api/controller/', //dev
			usersUrl: process.env.REACT_APP_USERS,
			authUrl: process.env.REACT_APP_AUTH,
			adminUserUrl: process.env.REACT_APP_ADMIN_USER,
			communicationUrl: process.env.REACT_APP_COMMUNICATION,
			filesUrl: process.env.REACT_APP_FILES,
			jobstreamUrl: process.env.REACT_APP_JOBSTREAM,
			profileUrl: process.env.REACT_APP_PROFILE,
			reportUrl: process.env.REACT_APP_REPORT,
		},
		// rest of your translation object
	},
	// other global config variables you wish
}
