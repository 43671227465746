import React from 'react'
import {Row, Col} from 'reactstrap'
import {UncontrolledDropdown, DropdownMenu, TabContent, TabPane, Nav, NavItem, NavLink, DropdownItem, DropdownToggle, Badge} from 'reactstrap'
import axios from 'axios'
import {CircularProgress} from '@material-ui/core'
import classnames from 'classnames'
//import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {history} from '../../../history'
import SweetAlert from 'react-bootstrap-sweetalert'
import {generateRefreshToken} from '../../../serviceWorker'
const handleNavigation = (e, path) => {
	e.preventDefault()
	history.push(path)
}
class NavbarUser extends React.PureComponent {
	state = {
		overallcount_notification: '',
		notify_response_currentdate: '',
		notification_list_navbar: [],
		defaultAlert: false,
		confirmAlert: false,
		cancelAlert: false,
		navbarSearch: false,
		activeTab: '1',
		active: '1',
		activeIndex: 0,
		langDropdown: false,
		ipurl: global.config.geturl.ipaddress.communicationUrl,
		usercode: localStorage.getItem('usercode'),
		varusername_login: localStorage.getItem('nameoftheuser'),
		varlast_logindate: localStorage.getItem('lastloginval'),
		varlast_logintime: localStorage.getItem('logintimeval'),
		varcurrent_date: localStorage.getItem('currentdate'),
		varcurrent_time: localStorage.getItem('currenttime'),
		suggestions: [],
		infocircle_icon: require('../../../assets/img/portrait/small/info_circle.png'),
		opacity_notify_mesg: '',
		empapproval_list: [],
		jobapproval_list: [],
		abusemanage_list: [],
		contactus_list: [],
		loading: true,
	}
	handleNavbarSearch = () => {
		this.setState({
			navbarSearch: !this.state.navbarSearch,
		})
	}

	handleAlert = (state, value) => {
		this.setState({[state]: value})
	}
	logout() {
		this.handleAlert('defaultAlert', false)
		this.handleAlert('confirmAlert', false)
		localStorage.setItem('usercode', '')
		localStorage.setItem('userrolename', '')
		localStorage.setItem('designationname', '')
		localStorage.setItem('statuscode', '')
		localStorage.setItem('userrolecode', '')
		localStorage.setItem('designationcode', '')
		localStorage.clear()
		history.push('/')
	}

	async componentDidMount() {
		// this.notificationlist_empapproval();
		// this.notificationlist_jobapprovallist();
		// this.notificationlist_abusemanagelist();
		// this.notificationlist_contactuslist();
		if (this.state.overallcount_notification !== '') {
			this.notification_bell()
		}
		await Promise.all([this.listcount(), this.notificationlist()])
	}

	//notification bell
	notification_bell() {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationupdatestatus/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				typecode: 1,
				apptypecode: 3,
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.notification_bell()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 2) {
					this.setState({overallcount_notification: ''})
				}
			}
		})
	}
	onMouseShow = () => {
		this.setState({hover_content: 'true'})
	}
	onMouseRemove = () => {
		this.setState({hover_content: ''})
	}
	toggleTab = tab => {
		try {
			if (this.state.activeTab !== tab) {
				this.setState({activeTab: (this.state.activeTab = tab)})
			}
		} catch (error) {}
	}
	toggle = tab => {
		try {
			if (tab == 1) {
				this.notificationlist_empapproval()
			} else if (tab == 2) {
				this.notificationlist_jobapprovallist()
			} else if (tab == 3) {
				this.notificationlist_abusemanagelist()
			} else if (tab == 4) {
				this.notificationlist_contactuslist()
			}
			if (tab == 2) {
				this.setState({varhideid: true})
				this.setState({varshowid: false})
			} else {
				this.setState({varhideid: false})
				this.setState({varshowid: true})
			}
			if (this.state.active !== tab) {
				this.setState({active: tab})
			}
		} catch (error) {}
	}
	//notification dismiss single
	dismiss_single(notificationtypecode, notificationcode) {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/dismissnotification/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				typecode: 1,
				apptypecode: 3,
				notificationcode: notificationcode,
			},
			data: {notificationtypecode: [notificationtypecode]},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.dismiss_single()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 2) {
					this.notificationlist_empapproval()
					this.notificationlist_jobapprovallist()
					this.notificationlist_abusemanagelist()
					this.notificationlist_contactuslist()
					this.notificationlist()
				} else {
				}
			}
		})
	}
	//viewed status
	viewedstatus_admin(notificationtypecode, notificationcode) {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/viewedstatus/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
				notificationcode: notificationcode,
			},
			date: {notificationtypecode: [notificationtypecode]},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.viewedstatus_admin()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 2) {
					this.notificationlist_empapproval()
					this.notificationlist_jobapprovallist()
					this.notificationlist_abusemanagelist()
					this.notificationlist_contactuslist()
					this.notificationlist()
					this.setState({opacity_notify_mesg: 'opacity_noti_msg'})
				}
			}
		})
	}
	//dismiss all
	dismiss_all() {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/dismissnotification/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				typecode: 2,
				apptypecode: 3,
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.dismiss_all()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 2) {
					this.notificationlist_empapproval()
					this.notificationlist_jobapprovallist()
					this.notificationlist_abusemanagelist()
					this.notificationlist_contactuslist()
					this.notificationlist()
					this.notification_bell()
				}
			}
		})
	}
	//notification count overall
	listcount() {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationcount/',
			// crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.listcount()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 4) {
					let overallcount_notification = response.data.notification_json_result.overall_count
					this.setState({
						overallcount_notification: overallcount_notification,
					})
				} else {
					this.setState({overallcount_notification: ''})
				}
			}
		})
	}
	// notification_view_list() {
	//   if (this.state.loading === true) {
	//     return (
	//       <div className="norecord-info">
	//         <CircularProgress color="inherit" />
	//       </div>
	//     );
	//   } else if (
	//     this.state.notification_list_navbar === "" ||
	//     this.state.notification_list_navbar.length === 0
	//   ) {
	//     return (
	//       <div className="norecord-info">
	//         <small> No Notifications yet </small>
	//       </div>
	//     );
	//   }
	//   if (
	//     this.state.notification_list_navbar &&
	//     this.state.notification_list_navbar.length > 0
	//   ) {
	//     return this.state.notification_list_navbar.map((el, index) => {
	//       const {
	//         notificationmessage,
	//         notificationtime,
	//         notificationcode,
	//         notificationdetails,
	//         notificationtypecode,
	//         notificationtypename,
	//         notificationtypeid,
	//       } = el;
	//       var common_time = 0;
	//       var msPerMinute = 60 * 1000;
	//       var msPerHour = msPerMinute * 60;
	//       var msPerDay = msPerHour * 24;
	//       var msPerMonth = msPerDay * 30;
	//       var msPerYear = msPerDay * 365;
	//       var elapsed = this.state.notify_response_currentdate - notificationtime;
	//       if (elapsed === 0) {
	//         common_time = "Just now";
	//       }
	//       if (elapsed < msPerMinute) {
	//         common_time = Math.round(elapsed / 1000) + " seconds ago";
	//       } else if (elapsed < msPerHour) {
	//         common_time = Math.round(elapsed / msPerMinute) + " mins ago";
	//       } else if (elapsed < msPerDay) {
	//         common_time = Math.round(elapsed / msPerHour) + " hours ago";
	//       } else if (elapsed < msPerMonth) {
	//         common_time = Math.round(elapsed / msPerDay) + " days ago";
	//       } else if (elapsed < msPerYear) {
	//         common_time = Math.round(elapsed / msPerMonth) + " months ago";
	//       } else {
	//         common_time = Math.round(elapsed / msPerYear) + " years ago";
	//       }
	//       return (
	//         <div key={index}>
	//           <div
	//             className="notify-border"
	//             onMouseEnter={this.onMouseShow}
	//             onMouseLeave={this.onMouseRemove}
	//           >
	//             <Row className={"notify-row " + this.state.opacity_notify_mesg}>
	//               <Col
	//                 md="9"
	//                 onClick={() =>
	//                   this.notification_message_clic(
	//                     notificationtypecode,
	//                     notificationtypeid,
	//                     notificationdetails
	//                   )
	//                 }
	//               >
	//                 <div>
	//                   <div headingtag="h6">
	//                     <span className="cursor-pointer">
	//                       {" "}
	//                       {notificationtypename}{" "}
	//                     </span>
	//                   </div>
	//                 </div>
	//                 <div className="notify-margin">
	//                   <div headingtag="h6" style={{ lineHeight: "91%" }}>
	//                     <span
	//                       className="cursor-pointer"
	//                       onClick={() =>
	//                         this.viewedstatus_admin(
	//                           notificationtypecode,
	//                           notificationcode
	//                         )
	//                       }
	//                     >
	//                       {notificationmessage}
	//                     </span>
	//                   </div>
	//                 </div>
	//               </Col>
	//               <Col md="3">
	//                 <div className="btton">
	//                   {this.state.hover_content ? (
	//                     <button
	//                       className="image_btn_style1"
	//                       onClick={() =>
	//                         this.dismiss_single(
	//                           notificationtypecode,
	//                           notificationcode
	//                         )
	//                       }
	//                     >
	//                       <i
	//                         className="fa fa-times closeicon"
	//                         aria-hidden="true"
	//                       ></i>
	//                     </button>
	//                   ) : (
	//                     ""
	//                   )}
	//                 </div>
	//                 {/* <small> <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00" >
	//               {notify_time} </time>  </small> */}
	//                 <div className="notify-time" headingtag="h6">
	//                   <small>{common_time} </small>
	//                 </div>
	//               </Col>
	//             </Row>
	//           </div>
	//         </div>
	//       );
	//     });
	//   }
	// }
	notification_view_empapproval() {
		try {
			if (this.state.loading === true) {
				return (
					<div className='norecord-info'>
						<CircularProgress className='customCircularProgress' />
					</div>
				)
			} else if (this.state.empapproval_list === '' || this.state.empapproval_list.length === 0) {
				return (
					<div className='norecord-info'>
						<small>No Notification Found</small>
					</div>
				)
			}
			if (this.state.empapproval_list && this.state.empapproval_list.length > 0) {
				return this.state.empapproval_list.map((el, index) => {
					const {
						notificationmessage,
						notificationtime,
						notificationcode,
						notificationdetails,
						notificationtypecode,
						notificationtypename,
						notificationtypeid,
					} = el
					var common_time = 0
					var msPerMinute = 60 * 1000
					var msPerHour = msPerMinute * 60
					var msPerDay = msPerHour * 24
					var msPerMonth = msPerDay * 30
					var msPerYear = msPerDay * 365
					var elapsed = this.state.notify_response_currentdate - notificationtime
					if (elapsed === 0) {
						common_time = 'Just now'
					}
					if (elapsed < msPerMinute) {
						common_time = Math.round(elapsed / 1000) + ' seconds ago'
					} else if (elapsed < msPerHour) {
						common_time = Math.round(elapsed / msPerMinute) + ' mins ago'
					} else if (elapsed < msPerDay) {
						common_time = Math.round(elapsed / msPerHour) + ' hours ago'
					} else if (elapsed < msPerMonth) {
						common_time = Math.round(elapsed / msPerDay) + ' days ago'
					} else if (elapsed < msPerYear) {
						common_time = Math.round(elapsed / msPerMonth) + ' months ago'
					} else {
						common_time = Math.round(elapsed / msPerYear) + ' years ago'
					}
					return (
						<div key={index}>
							<div className='notify-border' onMouseEnter={this.onMouseShow} onMouseLeave={this.onMouseRemove}>
								<Row className={'notify-row ' + this.state.opacity_notify_mesg}>
									<Col md='9' onClick={() => this.notification_message_clic(notificationtypecode, notificationtypeid, notificationdetails)}>
										<div>
											<div headingtag='h6'>
												<span className='cursor-pointer'> {notificationtypename} </span>
											</div>
										</div>
										<div className='notify-margin'>
											<div headingtag='h6' style={{lineHeight: '91%'}}>
												<span className='cursor-pointer' onClick={() => this.viewedstatus_admin(notificationtypecode, notificationcode)}>
													{notificationmessage}
												</span>
											</div>
										</div>
									</Col>
									<Col md='3'>
										<div className='btton'>
											{this.state.hover_content ? (
												<button className='image_btn_style1' onClick={() => this.dismiss_single(notificationtypecode, notificationcode)}>
													<i className='fa fa-times closeicon' aria-hidden='true'></i>
												</button>
											) : (
												''
											)}
										</div>
										{/* <small> <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00" >
                  {notify_time} </time>  </small> */}
										<div className='notify-time' headingtag='h6'>
											<small>{common_time} </small>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
				})
			}
		} catch (error) {}
	}
	notification_view_jobapproval() {
		try {
			if (this.state.loading === true) {
				return (
					<div className='norecord-info'>
						<CircularProgress className='customCircularProgress' />
					</div>
				)
			} else if (this.state.jobapproval_list === '' || this.state.jobapproval_list.length === 0) {
				return (
					<div className='norecord-info'>
						<small>No Notification Found</small>
					</div>
				)
			}
			if (this.state.jobapproval_list && this.state.jobapproval_list.length > 0) {
				return this.state.jobapproval_list.map((el, index) => {
					const {
						notificationmessage,
						notificationtime,
						notificationcode,
						notificationdetails,
						notificationtypecode,
						notificationtypename,
						notificationtypeid,
					} = el
					var common_time = 0
					var msPerMinute = 60 * 1000
					var msPerHour = msPerMinute * 60
					var msPerDay = msPerHour * 24
					var msPerMonth = msPerDay * 30
					var msPerYear = msPerDay * 365
					var elapsed = this.state.notify_response_currentdate - notificationtime
					if (elapsed === 0) {
						common_time = 'Just now'
					}
					if (elapsed < msPerMinute) {
						common_time = Math.round(elapsed / 1000) + ' seconds ago'
					} else if (elapsed < msPerHour) {
						common_time = Math.round(elapsed / msPerMinute) + ' mins ago'
					} else if (elapsed < msPerDay) {
						common_time = Math.round(elapsed / msPerHour) + ' hours ago'
					} else if (elapsed < msPerMonth) {
						common_time = Math.round(elapsed / msPerDay) + ' days ago'
					} else if (elapsed < msPerYear) {
						common_time = Math.round(elapsed / msPerMonth) + ' months ago'
					} else {
						common_time = Math.round(elapsed / msPerYear) + ' years ago'
					}
					return (
						<div key={index}>
							<div className='notify-border' onMouseEnter={this.onMouseShow} onMouseLeave={this.onMouseRemove}>
								<Row className={'notify-row ' + this.state.opacity_notify_mesg}>
									<Col md='9' onClick={() => this.notification_message_clic(notificationtypecode, notificationtypeid, notificationdetails)}>
										<div>
											<div headingtag='h6'>
												<span className='cursor-pointer'> {notificationtypename} </span>
											</div>
										</div>
										<div className='notify-margin'>
											<div headingtag='h6' style={{lineHeight: '91%'}}>
												<span className='cursor-pointer' onClick={() => this.viewedstatus_admin(notificationtypecode, notificationcode)}>
													{notificationmessage}
												</span>
											</div>
										</div>
									</Col>
									<Col md='3'>
										<div className='btton'>
											{this.state.hover_content ? (
												<button className='image_btn_style1' onClick={() => this.dismiss_single(notificationtypecode, notificationcode)}>
													<i className='fa fa-times closeicon' aria-hidden='true'></i>
												</button>
											) : (
												''
											)}
										</div>
										{/* <small> <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00" >
                  {notify_time} </time>  </small> */}
										<div className='notify-time' headingtag='h6'>
											<small>{common_time} </small>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
				})
			}
		} catch (error) {}
	}
	notification_view_abuse() {
		try {
			if (this.state.loading === true) {
				return (
					<div className='norecord-info'>
						<CircularProgress className='customCircularProgress' />
					</div>
				)
			} else if (this.state.abusemanage_list === '' || this.state.abusemanage_list.length === 0) {
				return (
					<div className='norecord-info'>
						<small>No Notification Found</small>
					</div>
				)
			}
			if (this.state.abusemanage_list && this.state.abusemanage_list.length > 0) {
				return this.state.abusemanage_list.map((el, index) => {
					const {
						notificationmessage,
						notificationtime,
						notificationcode,
						notificationdetails,
						notificationtypecode,
						notificationtypename,
						notificationtypeid,
					} = el
					var common_time = 0
					var msPerMinute = 60 * 1000
					var msPerHour = msPerMinute * 60
					var msPerDay = msPerHour * 24
					var msPerMonth = msPerDay * 30
					var msPerYear = msPerDay * 365
					var elapsed = this.state.notify_response_currentdate - notificationtime
					if (elapsed === 0) {
						common_time = 'Just now'
					}
					if (elapsed < msPerMinute) {
						common_time = Math.round(elapsed / 1000) + ' seconds ago'
					} else if (elapsed < msPerHour) {
						common_time = Math.round(elapsed / msPerMinute) + ' mins ago'
					} else if (elapsed < msPerDay) {
						common_time = Math.round(elapsed / msPerHour) + ' hours ago'
					} else if (elapsed < msPerMonth) {
						common_time = Math.round(elapsed / msPerDay) + ' days ago'
					} else if (elapsed < msPerYear) {
						common_time = Math.round(elapsed / msPerMonth) + ' months ago'
					} else {
						common_time = Math.round(elapsed / msPerYear) + ' years ago'
					}
					return (
						<div key={index}>
							<div className='notify-border' onMouseEnter={this.onMouseShow} onMouseLeave={this.onMouseRemove}>
								<Row className={'notify-row ' + this.state.opacity_notify_mesg}>
									<Col md='9' onClick={() => this.notification_message_clic(notificationtypecode, notificationtypeid, notificationdetails)}>
										<div>
											<div headingtag='h6'>
												<span className='cursor-pointer'> {notificationtypename} </span>
											</div>
										</div>
										<div className='notify-margin'>
											<div headingtag='h6' style={{lineHeight: '91%'}}>
												<span className='cursor-pointer' onClick={() => this.viewedstatus_admin(notificationtypecode, notificationcode)}>
													{notificationmessage}
												</span>
											</div>
										</div>
									</Col>
									<Col md='3'>
										<div className='btton'>
											{this.state.hover_content ? (
												<button className='image_btn_style1' onClick={() => this.dismiss_single(notificationtypecode, notificationcode)}>
													<i className='fa fa-times closeicon' aria-hidden='true'></i>
												</button>
											) : (
												''
											)}
										</div>
										{/* <small> <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00" >
                  {notify_time} </time>  </small> */}
										<div className='notify-time' headingtag='h6'>
											<small>{common_time} </small>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
				})
			}
		} catch (error) {}
	}
	notification_view_contactus() {
		try {
			if (this.state.loading === true) {
				return (
					<div className='norecord-info'>
						<CircularProgress className='customCircularProgress' />
					</div>
				)
			} else if (this.state.contactus_list === '' || this.state.contactus_list.length === 0) {
				return (
					<div className='norecord-info'>
						<small>No Notification Found</small>
					</div>
				)
			}
			if (this.state.contactus_list && this.state.contactus_list.length > 0) {
				return this.state.contactus_list.map((el, index) => {
					const {
						notificationmessage,
						notificationtime,
						notificationcode,
						notificationdetails,
						notificationtypecode,
						notificationtypename,
						notificationtypeid,
					} = el
					var common_time = 0
					var msPerMinute = 60 * 1000
					var msPerHour = msPerMinute * 60
					var msPerDay = msPerHour * 24
					var msPerMonth = msPerDay * 30
					var msPerYear = msPerDay * 365
					var elapsed = this.state.notify_response_currentdate - notificationtime
					if (elapsed === 0) {
						common_time = 'Just now'
					}
					if (elapsed < msPerMinute) {
						common_time = Math.round(elapsed / 1000) + ' seconds ago'
					} else if (elapsed < msPerHour) {
						common_time = Math.round(elapsed / msPerMinute) + ' mins ago'
					} else if (elapsed < msPerDay) {
						common_time = Math.round(elapsed / msPerHour) + ' hours ago'
					} else if (elapsed < msPerMonth) {
						common_time = Math.round(elapsed / msPerDay) + ' days ago'
					} else if (elapsed < msPerYear) {
						common_time = Math.round(elapsed / msPerMonth) + ' months ago'
					} else {
						common_time = Math.round(elapsed / msPerYear) + ' years ago'
					}
					return (
						<div key={index}>
							<div className='notify-border' onMouseEnter={this.onMouseShow} onMouseLeave={this.onMouseRemove}>
								<Row className={'notify-row ' + this.state.opacity_notify_mesg}>
									<Col md='9' onClick={() => this.notification_message_clic(notificationtypecode, notificationtypeid, notificationdetails)}>
										<div>
											<div headingtag='h6'>
												<span className='cursor-pointer'> {notificationtypename} </span>
											</div>
										</div>
										<div className='notify-margin'>
											<div headingtag='h6' style={{lineHeight: '91%'}}>
												<span className='cursor-pointer' onClick={() => this.viewedstatus_admin(notificationtypecode, notificationcode)}>
													{notificationmessage}
												</span>
											</div>
										</div>
									</Col>
									<Col md='3'>
										<div className='btton'>
											{this.state.hover_content ? (
												<button className='image_btn_style1' onClick={() => this.dismiss_single(notificationtypecode, notificationcode)}>
													<i className='fa fa-times closeicon' aria-hidden='true'></i>
												</button>
											) : (
												''
											)}
										</div>
										{/* <small> <time className="media-meta" dateTime="2015-06-11T18:29:20+08:00" >
                  {notify_time} </time>  </small> */}
										<div className='notify-time' headingtag='h6'>
											<small>{common_time} </small>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					)
				})
			}
		} catch (error) {}
	}
	notificationlist_empapproval() {
		var accessToken = localStorage.getItem('accessToken')
		this.setState({loading: true})
		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationlist/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
				typecode: 1,
				languagecode: 2,
			},
			data: {
				notificationtypecode: [5], //Employer Approval
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.notificationlist_empapproval()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 4) {
					//current time
					let notification_currenttime = response.data.notification_json_result.currenttime
					if (notification_currenttime) {
						this.setState({
							notify_response_currentdate: notification_currenttime,
						})
					}
					let empapproval_list = response.data.notification_json_result.nodification_list
					if (empapproval_list) {
						JSON.stringify(empapproval_list)
						this.setState({
							empapproval_list: empapproval_list,
							loading: false,
						})
					}
				} else {
					this.setState({empapproval_list: [], loading: false})
				}
			}
		})
	}
	notificationlist_jobapprovallist() {
		var accessToken = localStorage.getItem('accessToken')
		this.setState({loading: true})
		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationlist/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
				typecode: 1,
				languagecode: 2,
			},
			data: {
				notificationtypecode: [3], //Job Post Approval
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.notificationlist_jobapprovallist()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 4) {
					//current time
					let notification_currenttime = response.data.notification_json_result.currenttime
					if (notification_currenttime) {
						this.setState({
							notify_response_currentdate: notification_currenttime,
						})
					}
					let jobapproval_list = response.data.notification_json_result.nodification_list
					if (jobapproval_list) {
						JSON.stringify(jobapproval_list)
						this.setState({
							jobapproval_list: jobapproval_list,
							loading: false,
						})
					}
				} else {
					this.setState({jobapproval_list: [], loading: false})
				}
			}
		})
	}
	notificationlist_abusemanagelist() {
		var accessToken = localStorage.getItem('accessToken')
		this.setState({loading: true})
		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationlist/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
				typecode: 1,
				languagecode: 2,
			},
			data: {
				notificationtypecode: [4], //Abuse Management
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.notificationlist_abusemanagelist()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 4) {
					//current time
					let notification_currenttime = response.data.notification_json_result.currenttime
					if (notification_currenttime) {
						this.setState({
							notify_response_currentdate: notification_currenttime,
						})
					}
					let abusemanage_list = response.data.notification_json_result.nodification_list
					if (abusemanage_list) {
						JSON.stringify(abusemanage_list)
						this.setState({
							abusemanage_list: abusemanage_list,
							loading: false,
						})
					}
				} else {
					this.setState({abusemanage_list: [], loading: false})
				}
			}
		})
	}
	notificationlist_contactuslist() {
		var accessToken = localStorage.getItem('accessToken')
		this.setState({loading: true})
		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationlist/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
				typecode: 1,
				languagecode: 2,
			},
			data: {
				notificationtypecode: [6], //Contact Us Management
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.notificationlist_contactuslist()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 4) {
					//current time
					let notification_currenttime = response.data.notification_json_result.currenttime
					if (notification_currenttime) {
						this.setState({
							notify_response_currentdate: notification_currenttime,
						})
					}
					let contactus_list = response.data.notification_json_result.nodification_list
					if (contactus_list) {
						JSON.stringify(contactus_list)
						this.setState({contactus_list: contactus_list, loading: false})
					}
				} else {
					this.setState({contactus_list: [], loading: false})
				}
			}
		})
	}
	//notification list
	notificationlist() {
		var accessToken = localStorage.getItem('accessToken')

		axios({
			method: 'Post',
			url: this.state.ipurl + 'notification/notificationlist/',
			crossdomain: true,
			headers: {
				Authorization: 'Bearer ' + accessToken,
				'Content-Type': 'application/json',
			},
			params: {
				usercode: Number(this.state.usercode),
				ipaddress: localStorage.getItem('ipaddress'),
				apptypecode: 3,
				typecode: 0,
				languagecode: 2,
			},
			data: {
				notificationtypecode: [0], //All
			},
		}).then(async response => {
			if (response.data.status == 401) {
				await generateRefreshToken().then(result => {
					if (result == 1) {
						this.notificationlist()
					} else {
						// alert('Unauthorized')
					}
				})
			} else {
				if (response.data.notification_json_result.varstatuscode === 4) {
					//current time
					let notification_currenttime = response.data.notification_json_result.currenttime
					if (notification_currenttime) {
						this.setState({
							notify_response_currentdate: notification_currenttime,
						})
					}
					let notification_list_navbar = response.data.notification_json_result.nodification_list
					if (notification_list_navbar) {
						JSON.stringify(notification_list_navbar)
						this.setState({
							notification_list_navbar: notification_list_navbar,
						})
					}
				} else {
					this.setState({notification_list_navbar: []})
				}
			}
		})
	}
	notification_message_clic(notify_code, notificationtypeid, notificationdetails) {
		if (notify_code === 7) {
			// history.push("/approval/Employer_Approval_List");
			window.open('/approval/Employer_Approval_List', '_blank')
		} else if (notify_code === 11) {
			history.push('/subscription_contactus')
		} else if (notify_code === 6) {
			if (notificationdetails.apptypecode === 1) {
				localStorage.setItem('employeecode', notificationdetails.usercode)
				history.push('/Contact_us')
			} else if (notificationdetails.apptypecode === 2) {
				localStorage.setItem('employercode', notificationdetails.usercode)
				// history.push("/management/Employer_Management_List");
				history.push('/Contact_us')
			}
		} else if (notify_code === 3) {
			localStorage.setItem('jobcode', notificationtypeid)
			// history.push("/management/JobPost_View");
			window.open('/management/JobPost_View', '_blank')
		} else if (notify_code === 4) {
			if (notificationdetails.apptypecode === 1) {
				history.push('/reporting/Abuse_Management_List')
				// localStorage.setItem('jobcode', notificationdetails.jobcode);
				// localStorage.setItem('employercode', notificationdetails.employercode);
				// localStorage.setItem('abusecode', notificationdetails.abusecode);
				// localStorage.setItem('employeecode', notificationdetails.employeecode);
				// history.push("/management/JobPost_View");
			} else if (notificationdetails.apptypecode === 2) {
				history.push('/reporting/Abuse_Management_List')
				// localStorage.setItem('employercode', notificationdetails.employercode);
				// localStorage.setItem('abusecode', notificationdetails.abusecode);
				// localStorage.setItem('employeecode', notificationdetails.employeecode);
				// history.push("/employee/Employee_Profile_View");
			}
		} else if (notify_code === 5) {
			localStorage.setItem('employercode', notificationtypeid)
			// history.push("/approval/Employer_View");
			window.open('/approval/Employer_View', '_blank')
		}
	}
	readallnotify() {
		history.push('/readall_notifications')
	}
	render() {
		return (
			<React.Fragment>
				<Row>
					<ul className='nav navbar-nav navbar-nav-user float-right'>
						<UncontrolledDropdown tag='li' className='dropdown-notification nav-item'>
							<DropdownToggle tag='a' className='nav-link nav-link-label'>
								<i
									className='cls-icon fa fa-bell fa-lg'
									onClick={() => {
										this.setState({
											activeTab: '1',
											active: '1',
										})
										this.notificationlist_empapproval()
									}}></i>
								{/* <Icon.Bell size={21} /> */}
								{this.state.overallcount_notification && this.state.overallcount_notification > 0 ? (
									<Badge
										pill
										className='badge-up bg-white'
										onClick={() => {
											if (this.state.overallcount_notification > 0) {
												this.notification_bell()
												this.setState({
													activeTab: '1',
												})
												this.notificationlist_empapproval()
											} else {
												this.setState({
													activeTab: '1',
													active: '1',
												})
												this.notificationlist_empapproval()
											}
										}}>
										{' '}
										{this.state.overallcount_notification}{' '}
									</Badge>
								) : (
									''
								)}
							</DropdownToggle>
							<DropdownMenu tag='ul' right className='dropdown-menu-media'>
								<li className='notify-back'>
									<div className='dropdown-header mt-0'>
										{/* <h3 className="text-white bg-mycolortext">{this.state.overallcount_notification} New</h3>
                <h3 className="notification-title bg-mycolortext">App Notifications</h3> */}
										<h3 className='notify-title'>You have {this.state.overallcount_notification} New Notifications</h3>
										{this.state.notification_list_navbar && this.state.notification_list_navbar.length === 0 ? (
											''
										) : (
											<div className='dismiss-all cursor-pointer' onClick={() => this.dismiss_all()}>
												Dismiss all
											</div>
										)}
									</div>
								</li>
								{/* <div className="notify-scrol">
                  {this.notification_view_list()}
                </div> */}
								<div className='notify-scrol'>
									{/* {this.notification_view_list()} */}
									<TabContent activeTab={this.state.activeTab}>
										<TabPane tabId='1'>
											<Nav tabs>
												<Row style={{width: '100%'}}>
													<Col md='3'>
														<NavItem>
															<NavLink
																style={{color: '#000000 !important'}}
																className={classnames({
																	active: this.state.active === '1',
																})}
																onClick={() => {
																	this.toggle('1')
																}}>
																{/* <div>
                                  {this.state.active != 1  ?
                                    <Badge className="badge-news bg-mycolor-news">
                                      {this.state.jobs_invidual_count}
                                    </Badge> : null} </div> */}
																Employer Approval
															</NavLink>
														</NavItem>
													</Col>
													<Col md='3'>
														<NavItem>
															<NavLink
																style={{color: '#000000 !important'}}
																className={classnames({
																	active: this.state.active === '2',
																})}
																onClick={() => {
																	this.toggle('2')
																}}>
																{/* <div>
                                  {this.state.active != 2  ?
                                    <Badge className="badge-new bg-mycolor-news">
                                      {this.state.news_invidual_count}
                                    </Badge> : null} </div> */}
																Job Approval
															</NavLink>
														</NavItem>
													</Col>
													<Col md='3'>
														<NavItem>
															<NavLink
																style={{color: '#000000 !important'}}
																className={classnames({
																	active: this.state.active === '3',
																})}
																onClick={() => {
																	this.toggle('3')
																}}>
																{/* <div>
                                  {this.state.active != 3 ?
                                    <Badge className="badge-events bg-mycolor-news">
                                      {this.state.events_invidual_count}
                                    </Badge> : null} </div> */}
																Abuse<br></br>
																<br></br>
															</NavLink>
														</NavItem>
													</Col>
													<Col md='3'>
														<NavItem>
															<NavLink
																style={{color: '#000000 !important'}}
																className={classnames({
																	active: this.state.active === '4',
																})}
																onClick={() => {
																	this.toggle('4')
																}}>
																{/* <div>
                                  {this.state.active != 2  ?
                                    <Badge className="badge-new bg-mycolor-news">
                                      {this.state.news_invidual_count}
                                    </Badge> : null} </div> */}
																Contact Us<br></br>
																<br></br>
															</NavLink>
														</NavItem>
													</Col>
												</Row>
											</Nav>

											<TabContent activeTab={this.state.active}>
												<TabPane tabId='1'>{this.notification_view_empapproval()}</TabPane>
												<TabPane tabId='2'>{this.notification_view_jobapproval()}</TabPane>
												<TabPane tabId='3'>{this.notification_view_abuse()}</TabPane>
												<TabPane tabId='4'>{this.notification_view_contactus()}</TabPane>
											</TabContent>
										</TabPane>
									</TabContent>
								</div>
								<li className='dropdown-menu-footer'>
									<DropdownItem
										tag='a'
										className='p-1 text-center'
										//  style={{ width: '370px' }}
									>
										<span onClick={() => this.readallnotify()} className='align-middle'>
											View all notifications
										</span>
									</DropdownItem>
								</li>
							</DropdownMenu>
						</UncontrolledDropdown>
						<UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
							<DropdownToggle tag='a' className='nav-link dropdown-user-link'>
								<div className='user-nav d-sm-flex d-none'>
									<span className='user-name text-bold-600'>
										{/* {this.props.userName} */}
										{this.state.varusername_login}
									</span>
									{/* <span className="user-name text-bold-600">
                Last login:
</span> */}
									<span style={{fontSize: '11px'}} className='user-name text-bold-600'>
										{this.state.varlast_logindate && this.state.varlast_logindate !== 'Invalid date' ? (
											<span>{this.state.varlast_logindate}</span>
										) : (
											<span> {this.state.varcurrent_date}</span>
										)}
										<br />
										{this.state.varlast_logintime && this.state.varlast_logintime !== undefined ? (
											// <span>{this.setState({varlast_logintime})}</span>
											<span>{this.state.varlast_logintime} </span>
										) : (
											<span>{this.state.varcurrent_time}</span>
										)}
									</span>
								</div>
								<span data-tour='user'>
									<img
										src={this.props.userImg}
										// className="round"
										className='rounded-circle'
										height='40'
										width='40'
										alt='avatar'
									/>
								</span>
							</DropdownToggle>
							{/* <UserDropdown {...this.props} /> */}
							<DropdownMenu id='navbarid'>
								<DropdownItem tag='a' href='#' onClick={e => handleNavigation(e, '/authentication/ChangePassword')}>
									<i className='color-myblack fa fa-key icon_style fa-lg'></i>
									<span className='align-middle'>Change Password</span>
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem
									tag='a'
									//  href="/"
									onClick={() => {
										history.push('/logout')
										// this.handleAlert("defaultAlert", true)
									}}>
									<i className='color-myblack fa fa-power-off icon_style fa-lg'></i>
									<span className='align-middle'>Log Out</span>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
						<div>
							<SweetAlert
								title='Are you sure want to Logout?'
								warning
								show={this.state.defaultAlert}
								showCancel
								reverseButtons
								confirmBtnBsStyle='submitalert-color'
								cancelBtnBsStyle='danger'
								confirmBtnText='Yes'
								cancelBtnText='Cancel'
								onConfirm={() => {
									this.logout()
								}}
								onCancel={() => {
									this.handleAlert('defaultAlert', false)
									this.handleAlert('confirmAlert', false)
								}}></SweetAlert>
						</div>
					</ul>
				</Row>
			</React.Fragment>
		)
	}
}
export default NavbarUser
